/** @jsx jsx */
import { jsx } from 'theme-ui'

const Taijitu = ({ height = `100%`, width, invert = false }) => {
  const transform = invert ? 'rotate(180)' : ''

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="-40 -40 80 80"
      aria-label="Icon Yin Yang Symbol"
      transform={transform}
    >
      <circle r="39" />
      <path
        d="M0,38a38,38 0 0 1 0,-76a19,19 0 0 1 0,38a19,19 0 0 0 0,38"
        fill="#fff"
      />
      <circle cy="19" r="5" fill="#fff" />
      <circle cy="-19" r="5" />
    </svg>
  )
}

export default Taijitu
