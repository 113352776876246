/** @jsx jsx */
// import React from "react"
import { jsx, Styled } from 'theme-ui'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Box, Text, Link } from '../components/ui'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Box variant="layout.section">
      <Styled.h3>Is Tai Chi for Me?</Styled.h3>
      <Text variant="p">
        Tai Chi is a mind-body art that teaches mindfulness, emotional balance,
        and physical health through the practice of slow and precise movements.
        Due to the gentle nature of the exercise, Tai Chi is accessible to
        people of any age or fitness level... yet its depth and precision make
        it a benefit to any athlete or martial artist looking for a challenge
        that won't injure the body.
      </Text>
      <Text variant="p">
        If you're tired of quick-fix health fads, or if the "no pain no gain"
        grind of the modern gym fills you with dread... you've come to the right
        place.
      </Text>
      <Box variant="layout.cta">
        <Link variant="link.button" to="/contact">
          Join Us For a Free Demo Class
        </Link>
      </Box>
    </Box>
    <Box variant="layout.section">
      <Text sx={styles.quote}>
        "Perfection is achieved, not when there is nothing more to add, but when
        there is nothing left to take away." - Antoine de Saint-Exupéry
      </Text>
    </Box>
    <Box variant="layout.section">
      <Styled.h3>Functional Mobility: Less Pain, More Gain</Styled.h3>
      <Text variant="p">
        Students begin by building a foundation of strength, balance, and
        flexibility through a unique combination of movements and stretches we
        call "Pine Tree" exercises. This time-tested routine developed by Sifu
        Andy Lee has helped students increase and maintain their level of
        physical function while learning key Tai Chi principles in a way that
        leaves you feeling invigorated instead of tired after practice.
      </Text>
    </Box>
    <Box variant="layout.section">
      <Styled.h3>Inner Peace: Stillness in Motion</Styled.h3>
      <Text variant="p">
        With a solid grounding in the basic principles through the Pine Tree
        exercises, students begin to learn the traditional postures of the Tai
        Chi form. These smooth and gentle motions seem easy at first glance, but
        through this practice they soon realize a deep understanding of their
        habits of movement. Students learn to release patterns of physical and
        mental tension that they've carried for years, and usually experience
        relief from aches and pains that have perplexed them.
      </Text>
      <Text variant="p">
        Advanced students can choose to go deeper into the philosophy of Tai
        Chi, or the martial art that Tai Chi originally came from, through push
        hands, weapons forms, and self defense techniques. Students develop a
        deep sense of physical and emotional calm, learning to ride the ups and
        downs of life without strain.
      </Text>
    </Box>
    <Box variant="layout.section">
      <Styled.h3>Tai Chi: A Lifelong Journey</Styled.h3>
      <Text variant="p">
        Whether for better health, good friends, or a love of the ever deeper
        layers of self discovery that is Tai Chi... many of our students stay
        with us for years, becoming part of a community of friends who support
        and learn from each other. Everyone learns at their own pace with help
        and encouragement from our teachers and fellow students who have all
        walked the same path.
      </Text>
    </Box>
    <Box variant="layout.cta">
      <Styled.h2>Ready to start your own Journey?</Styled.h2>
      <Box>
        <Link variant="link.button" to="/contact">
          Join Us For a Free Demo Class
        </Link>
      </Box>
    </Box>
  </Layout>
)

const styles = {
  quote: {
    variant: 'text.quote',
  },
  button: {
    mt: 2,
  },
}

export default IndexPage
