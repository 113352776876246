/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'
import { Styled, useColorMode } from 'theme-ui'
import Taijitu from './Taijitu'
import Logo from './TaiChiUSALogo'
// import { isLoggedIn } from '../utils/auth'

const NavLink = props => <Link sx={styles.link} {...props} />

const Header = props => {
  const [mode, setMode] = useColorMode()
  const toggleMode = () => {
    setMode(mode === 'dark' ? 'light' : 'dark')
  }

  return (
    <header sx={styles.header}>
      <Styled.div sx={styles.container}>
        <Link to="/" sx={{ variant: 'link.plain' }}>
          <Logo sx={styles.logo} />
        </Link>
        <Styled.div sx={styles.titleContainer}>
          <Styled.h1 sx={{ marginBottom: [0, 0, 0] }}>
            <Link to="/" sx={{ variant: 'link.plain' }}>
              Tai Chi USA
            </Link>
          </Styled.h1>
          <Styled.div sx={styles.subtitle}>
            Helping our students become healthier and happier since 1995.
          </Styled.div>
        </Styled.div>
      </Styled.div>
      <nav sx={styles.nav}>
        <Styled.div sx={styles.iconContainer} onClick={toggleMode}>
          <Taijitu invert={mode === 'dark'} />
        </Styled.div>
        <NavLink to="/">Members</NavLink>
        <NavLink to="/study">Study</NavLink>
        <NavLink to="/blog">Blog</NavLink>
        <NavLink to="/teachers">Teachers</NavLink>
        {/* {isLoggedIn() ? (
          <NavLink to="/members/logout">Logout</NavLink>
        ) : (
          <NavLink to="/members/login">Login</NavLink>
        )} */}
      </nav>
    </header>
  )
}

const styles = {
  header: {
    variant: 'layout.header',
  },
  container: {
    margin: `0 auto`,
    height: '100%',
    maxWidth: 'container',
    display: 'flex',
    flexDirection: ['column', 'row', 'row'],
    alignItems: 'center',
    padding: [1, 1, 3],
  },
  link: {
    height: '100%',
    color: 'text',
    textDecoration: 'none',
    p: [1, 2, 3],
    whiteSpace: 'nowrap',
    '&:hover': {
      color: 'background',
      backgroundColor: 'primary',
    },
  },
  iconContainer: {
    height: ['24px', '32px'],
  },
  titleContainer: {
    // height: "100%",
    textAlign: 'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: [1, 1, 3],
  },
  nav: {
    margin: `0 auto`,
    maxWidth: 'container',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    '> *': {
      mr: [1, 1, 2],
    },
    borderTop: theme => `1px solid ${theme.colors.text}`,
    borderBottom: theme => `1px solid ${theme.colors.text}`,
  },
  logo: {
    width: ['120px', '120px', '160px'],
    mx: [1, 2, 2],
  },
  subtitle: {},
}

export default Header
