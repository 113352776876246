/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx, Layout as ThemeLayout, Styled, Main } from 'theme-ui'
import Header from './header'
import Footer from './Footer'
import './layout.css'

const Layout = ({ children }) => {
  return (
    <ThemeLayout>
      <Styled.root sx={styles.root}>
        <Header />
        <Main sx={styles.main}>{children}</Main>
        <Footer />
      </Styled.root>
    </ThemeLayout>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const styles = {
  root: {
    variant: 'layout.root',
  },
  main: {
    variant: 'layout.main',
  },
}

export default Layout
