/** @jsx jsx */
import { jsx } from 'theme-ui'

const Footer = () => {
  return (
    <div sx={styles.footer}>
      <span>© {new Date().getFullYear()} TaiChiUSA</span>
      <div sx={styles.contact}>
        <div>taichi@taichiusa.com</div>
        <span>(732) 238-1414</span>
        <address>177 Main St. East Brunswick NJ 08816</address>
      </div>
    </div>
  )
}

const styles = {
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 1,
    padding: [2, 3, 4],
    variant: 'layout.footer',
  },
  contact: {
    textAlign: 'right',
  },
}

export default Footer
